<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button :disabled="entity.status > 0 && entity.status != 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button :disabled="entity.status > 0" v-shortkey="['ctrl', 'l']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.advancePaymentCode.$error }">
                                <label for="advancePaymentCode">Số tạm ứng</label>
                                <md-input name="advancePaymentCode" v-model="entity.advancePaymentCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.advancePaymentCode.required">Vui lòng nhập số tạm ứng</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Người đề nghị</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn người đề nghị</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.advancePaymentDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.advancePaymentDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.advancePaymentDate.required">Vui lòng chọn ngày lập</span>
                            </md-datepicker>
                        </div>  
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.note.$error }">
                               <label for="note">Lý do</label>
                               <md-input name="note" v-model="entity.note"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.note.required">Vui lòng nhập lý do</span>
                            </md-field>
                        </div>
                    </div>
                </div>

                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-request" class="tab-content" md-label="dự toán sửa chữa">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addEstimateRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th>
                                                            <th style="width:35%;">Số dự toán</th> 
                                                            <th style="width:25%;">Ngày dự toán</th>
                                                            <th class="right" style="width:25%;">Tổng dự toán</th> 
                                                            <th style="width:10%">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.estimateList" :key="'request-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input disabled v-model="item.estimate.estimateCode" :class="{'is-error': $v.entity.estimateList.$each[index].estimateId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openEstimateList(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm số dự toán</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    {{item.estimate.estimateDate}}
                                                                </div>
                                                            </td> 
                                                            <td class="right">
                                                                {{formatNumber(item.estimateAmount)}}
                                                            </td> 
                                                            <td class="grid-action">
                                                                <a @click="delEstimateRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-details" class="tab-content" md-label="Chi tiết tạm ứng">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th rowspan="2" style="width:70px;">#</th>
                                                            <th rowspan="2" style="width:15%;">Dự toán</th> 
                                                            <th rowspan="2" style="width:300px;">Tên chi phí</th>
                                                            <th colspan="3" style="width:450px; background-color: #B4E380;">Dự toán</th>
                                                            <th colspan="3" style="width:450px; background-color: #FFD18E;">Tạm ứng</th>
                                                        </tr> 
                                                        <tr> 
                                                            <th class="right" style="width:150px; background-color: #B4E380;">Số tiền</th>
                                                            <th class="right" style="width:150px; background-color: #B4E380;">VAT</th>
                                                            <th class="right" style="width:150px; background-color: #B4E380;">Thành tiền</th>
                                                            <th class="right" style="width:150px; background-color: #FFD18E;">Số tiền</th>
                                                            <th class="right" style="width:150px; background-color: #FFD18E;">VAT</th>
                                                            <th class="right" style="width:150px; background-color: #FFD18E;">Thành tiền</th>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div>{{ item.estimate.estimateCode }}</div>
                                                            </td>
                                                            <td class="center">
                                                                <div>{{item.fee.feeName}}</div> 
                                                            </td> 
                                                            <td class="number-right">
                                                                <div>{{formatNumber(item.estimatePrice)}}</div>
                                                            </td> 
                                                            <td class="number-right">
                                                                {{formatNumber(item.estimateVat) + '%'}}
                                                            </td>
                                                            <td class="number-right">
                                                                {{formatNumber(item.estimateAmount)}}
                                                            </td>
                                                            <td class="number-right">
                                                                <div>{{formatNumber(item.advancePaymentPrice)}}</div> 
                                                            </td>
                                                            <td class="number-right">
                                                                <div>{{formatNumber(item.advancePaymentVat)}}%</div> 
                                                            </td> 
                                                            <td class="number-right">
                                                                <div>{{formatNumber(item.advancePaymentAmount)}}</div> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="8">
                                                                <div class="sum-title">Tổng tạm ứng</div>
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(this.entity.amount)}}</div>
                                                            </td> 
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:20%;">Bộ phận/phòng</th> 
                                                            <th style="width:20%;">Người phê duyệt</th> 
                                                            <th style="width:23%;">Tiêu đề</th>
                                                            <th style="width:15%;">Chức năng</th>
                                                            <th style="width:10%;">Thứ tự</th> 
                                                            <th style="width:150px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input disabled v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}"  class="form-control" type="text">
                                                                    <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input disabled v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}"  class="form-control" type="text">
                                                                    <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.title" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <md-field>
                                                                    <md-select v-model="item.isCommenter" name="approveTypes" id="approveTypes">
                                                                        <md-option v-for="obj in approveTypes" :key="'type' + obj.value" :value="obj.value">{{obj.text}}</md-option>
                                                                    </md-select>
                                                                </md-field>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                </div>
                                                            </td> 
                                                            <td class="grid-action">
                                                                <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <deptList ref="deptList" @close="closeDept"/>
        <estimateList ref="estimateList" @close="closeEstimate"/>
        <approveList ref="approveList" @close="closeApprove"/>
   </div>
</template>
<script>
    import advancePaymentService from '../../../api/advancePaymentService';
    import estimateService from '../../../api/estimateService';
    import approveSettingService from '../../../api/approveSettingService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import staffList from '../../../components/popup/_StaffList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue';
    import estimateList from '../../../components/popup/_EstimateList.vue';
    import approveList from '../../../components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            estimateList,
            approveList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật tạm ứng'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                staffName: '',
                staffs: [],
                entity: { id: 0, status: 0, details: [], amount: 0, estimateList: [], approveList: [], note: '', deptId: 0, staffId: 0, staff: {id: 0, fullName: ''}, advancePaymentCode: '', advancePaymentDate: common.dateNow },
                approve: { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, deptId: 0, staff: { id: 0, fullName: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, objectType: 3 },
                estimate: { id: 'id_' + common.getFakeId(), estimate: { estimateCode: '', estimateDate: ''}, estimateAmount: 0 },
                estimateList: [],
                approveTypes: common.approveTypes,
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật tạm ứng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới tạm ứng';
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.entity.estimateList.push(this.estimate);
               this.getApproveSetting();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSummary(){
                this.entity.amount = 0;
                for (var i = 0; i < this.entity.estimateList.length; i++) {
                    this.entity.amount = parseFloat(this.entity.amount) + parseFloat(this.entity.estimateList[i].advancePaymentAmount);
                }
            },

            addEstimateRow(){
                const item = { id: 'id_' + common.getFakeId(), estimate: { estimateCode: '', estimateDate: ''}, estimateAmount: 0 };
                this.entity.estimateList.push(item);
            },

            //Repair Estimate Request
            delEstimateRow(item){
                const index = this.entity.estimateList.findIndex(x => x.id == item.id);
                const estimateId = this.entity.details[index].estimateId;
                this.entity.estimateList.splice(index, 1);
                this.entity.details = this.entity.details.filter(item => !(item.estimateId === estimateId));
                this.calSummary();
            },

            closeEstimate(item){
                const selected = this.entity.estimateList.findIndex(x => x.id == this.selectedId);
                this.entity.estimateList[selected].id = item.id;
                this.entity.estimateList[selected].estimate = item;
                this.entity.estimateList[selected].estimateId = item.id;
                this.entity.estimateList[selected].estimateAmount = item.estimateAmount;
                this.entity.estimateList[selected].advancePaymentAmount = item.estimateAmount;
                this.selectedId = '';
                this.getEstimateById(item.id);
            },

            openEstimateList(id){
                this.selectedId = id;
                this.$refs.estimateList.open(false);
            },
            
            getEstimateById(id){
                this.setLoading(true);
                estimateService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        for(var i = 0; i < response.data.details.length; i ++){
                            const detail = response.data.details[i];
                            const item = { 
                                estimate: response.data,
                                id: detail.id,
                                advancePaymentId: 0,
                                estimateId: detail.estimateId, 
                                fee: detail.fee,
                                feeId: detail.feeId, 
                                estimatePrice: detail.estimatePrice,
                                estimateVat: detail.estimateVat,
                                estimateAmount: detail.estimateAmount,
                                advancePaymentPrice: detail.estimatePrice, 
                                advancePaymentVat: detail.estimateVat,
                                advancePaymentAmount: detail.estimateAmount,
                                advancePaymentNote: '' 
                            };
                            this.entity.details.push(item);
                            this.calSummary();
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.$refs.estimateList.close();});
            },


            getCode(staffId){
                this.setLoading(true);
                advancePaymentService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.advancePaymentCode = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].staff = item;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept = item.department;
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
                this.getCode(val.id);
                this.entity.approveList[0].staffId = val.id;
                this.entity.approveList[0].staff = val;
                this.entity.approveList[0].deptId = val.department.id;
                this.entity.approveList[0].dept = val.department;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.repairAdvancePayment).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairAdvancePayment), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 2 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.estimateList.length > 0){
                    this.entity.estimateList = this.entity.estimateList.filter(item => !(item.repairEstimateId == 0));
                    this.entity.estimateList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.id == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.estimateList.length <= 0){
                    messageBox.showWarning("Vui lòng nhập tạm ứng");
                    return;
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng nhập chi tiết tạm ứng");
                    return;
                }
                if(this.entity.approveList.length <= 1){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                advancePaymentService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/advance-payment');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                advancePaymentService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/advance-payment');
                    }
                    else{
                        this.entity.status = 1;
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                advancePaymentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/advance-payment');
            }
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                deptId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                advancePaymentCode: { required },
                note: { required },
                advancePaymentDate: { required },
                estimateList: {
                    $each: {
                        estimateId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
       }
     })

</script>
